<script>
import { getUserNotificationSettings } from '@/graphql/user/getUserNotificationSettings.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  query() {
    return {
      query: getUserNotificationSettings
    };
  }
};
</script>
