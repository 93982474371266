<template>
  <section>
    <div class="font-bold text-md mb-2">My Team</div>
    <el-table
      v-loading="isLoading"
      border
      class="el-table-slim"
      :data="filteredTeam"
    >
      <table-empty-slot
        slot="empty"
        :is-loading="isLoading"
        :has-data="!!filteredTeam"
        class="p-4"
      >
        There are no members on your team.
        <a @click="$emit('invite')">Invite a new member</a>
      </table-empty-slot>

      <el-table-column label="Name">
        <template v-slot="{ row }">
          <div>
            <b>{{ row.name }}</b>
            <template v-if="row.supplierUser.tags.length > 0">
              • {{ row.supplierUser.tags.join(', ') }}
            </template>
          </div>
          <div class="text-dark-silver">{{ row.email }}{{ contacts(row) }}</div>
        </template>
      </el-table-column>
      <custom-el-table-column>
        <template slot="header">
          <span class="mr-2">User Role</span>
          <el-tooltip effect="light" placement="right">
            <template slot="content">
              <div class="w-48">
                This determines permissions to perform actions throughout the
                flytedesk platform.
                <a
                  href="https://intercom.help/flytedesk/en/articles/3102562-assigning-team-roles"
                  target="_blank"
                >
                  Learn more about user roles.
                </a>
              </div>
            </template>
            <icon :icon="infoCircled" class="text-dark-silver" />
          </el-tooltip>
        </template>
        <template v-slot="{ row }">
          {{ row.roles[0].name }}
        </template>
      </custom-el-table-column>
      <el-table-column label="Action" align="center" width="80">
        <template v-slot="{ row }">
          <el-popover
            placement="bottom-end"
            trigger="click"
            width="180"
            :disabled="!canEdit"
          >
            <tooltip-icon
              slot="reference"
              :is-disabled="!canEdit"
              :icon="ellipsisIcon"
              reference-class="cursor-pointer hover:text-dark-blue px-4"
              icon-class="icon-lg text-blue"
            />
            <el-button
              class="button-plain hover:text-blue p-0 pb-4"
              @click="$emit('edit', row)"
            >
              Edit User
            </el-button>
            <el-tooltip
              position="bottom"
              effect="light"
              :disabled="!isPrimaryContact(row)"
            >
              <div slot="content" class="text-left max-w-sm">
                A primary contact is required. To remove this selection from
                this user, designate a different user as primary contact.
              </div>
              <span>
                <el-button
                  :disabled="isMyAccount(row) || isPrimaryContact(row)"
                  class="button-plain hover:text-blue p-0"
                  @click="$emit('remove', row)"
                >
                  Remove User
                </el-button>
                <el-button
                  :disabled="isMyAccount(row)"
                  class="button-plain hover:text-blue p-0 pt-4"
                  @click="$emit('resetPassword', row)"
                >
                  Reset Password
                </el-button>
              </span>
            </el-tooltip>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import TooltipIcon from '@/components/Core/TooltipIcon';
import {
  chat as chatIcon,
  ellipsisV as ellipsisIcon,
  infoCircled
} from '@/vendor/icons';
import { ContactNames } from '@/constants';
import CustomElTableColumn from '@/components/Custom/CustomElTableColumn';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';

export default {
  components: {
    TableEmptySlot,
    TooltipIcon,
    CustomElTableColumn
  },
  props: {
    primaryContact: {
      type: Object,
      default: null
    },
    billingContact: {
      type: Object,
      default: null
    },
    optionalContacts: {
      type: Array,
      default: () => []
    },
    team: {
      type: Array,
      default: () => []
    },
    isLoading: Boolean
  },
  data() {
    return {
      chatIcon,
      ellipsisIcon,
      infoCircled,
      editDialogVisible: false
    };
  },
  computed: {
    canEdit() {
      const isSupplier = this.$store.getters['auth/isSupplier'];
      if (isSupplier) {
        return this.$can('pub_manage_team_members');
      } else {
        return this.$can('buyer_manage_team_members');
      }
    },
    filteredTeam() {
      if (this.team) {
        return this.team.filter(
          teamMember => teamMember.roles[0].name !== 'Super Admin'
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    contacts(user) {
      const primaryLabel =
        this.primaryContact && user.id === this.primaryContact.user_id
          ? ' • Primary Contact'
          : '';
      const billingLabel =
        this.billingContact && user.id === this.billingContact.user_id
          ? ' • Billing Contact'
          : '';

      const optionalContact = this.optionalContacts.find(
        contact => user.id === contact.user_id
      );
      const optionalLabel = optionalContact
        ? ` • ${ContactNames[optionalContact.contactable.contact_type]}`
        : '';
      return `${primaryLabel}${billingLabel}${optionalLabel}`;
    },
    /**
     * Checks if user is logged in as this account
     * @param user
     * @returns {boolean}
     */
    isMyAccount(user) {
      return user && user.id === this.$store.getters['auth/user'].id;
    },
    isPrimaryContact(user) {
      return this.primaryContact && user.id === this.primaryContact.user_id;
    }
  }
};
</script>
