<template>
  <section>
    <div class="flex flex-col">
      <iframe-v4 :src="url" :height="3000" />
    </div>
  </section>
</template>

<script>
import IframeV4 from '@/components/Core/IframeV4';

export default {
  components: { IframeV4 },
  computed: {
    supplier() {
      return this.$store.getters['auth/supplier'];
    },
    url() {
      return process.env.VUE_APP_V4_URL + '/v4/iframe/organization';
    }
  }
};
</script>
