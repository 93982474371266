<template>
  <div class="settings-notifications-tab">
    <user-notification-settings-query
      :loading.sync="isLoading"
      @data="notificationSettings = $event.me"
    />

    <div class="global-settings border-bottom pb-10 mb-10">
      <h2>Global Notification Settings</h2>

      <div class="mt-2">
        These settings affect all other notifications and will enable or disable
        notifications of that type across the platform
      </div>

      <div v-if="isPrimaryContact" class="mt-3 bold italic text-dark-silver">
        You are the primary contact for your organization, so you will always be
        notified about actions relating to flytedesk national campaigns.
        <br />
        You can still use the settings below to customize notifications related
        to your ad shop campaigns.
      </div>
      <user-mutation>
        <template v-slot="{ isSaving, updateUserSetting }">
          <div
            v-if="notificationSettings"
            class="global-notification-settings mt-5"
          >
            <el-checkbox
              :value="emailNotification.value"
              @input="updateUserSetting(NOTIFICATION_SETTINGS.EMAIL, $event)"
            >
              Email Notifications
              <el-tooltip effect="light" class="ml-2">
                <template #content>
                  <div class="text-md p-2">
                    <div>Enable or Disable Email notifications globally.</div>
                    <div class="text-dark-red mt-5">
                      NOTE: You will still receive transactional email
                      communication from us if this is disabled!
                    </div>
                  </div>
                </template>
                <icon :icon="infoIcon" />
              </el-tooltip>
            </el-checkbox>

            <el-checkbox
              :value="inPlatformNotification.value"
              @input="
                updateUserSetting(NOTIFICATION_SETTINGS.IN_PLATFORM, $event)
              "
            >
              In Platform Notifications
              <el-tooltip effect="light" class="ml-2">
                <template #content>
                  <div class="text-md p-2">
                    <div>
                      <div>
                        Enable or Disable In Platform notifications globally.
                      </div>
                      <div class="mt-3">
                        These are the notifications that appear in the
                        Notifications Panel while you are using the Flytedesk
                        platform.
                      </div>
                    </div>
                  </div>
                </template>
                <icon :icon="infoIcon" />
              </el-tooltip>
            </el-checkbox>
          </div>
        </template>
      </user-mutation>
    </div>

    <div class="user-type-notifications">
      <h2>Notification Types</h2>
      <div class="mt-5">
        <user-mutation>
          <template v-slot="{ updateUserNotificationType, isSaving }">
            <el-table class="el-table-slim" border :data="notificationTypes">
              <template v-slot:empty>
                <table-empty-slot
                  :is-loading="isLoading"
                  :has-data="!!notificationTypes"
                  empty-message="There are no notifications that can be edited at this time. Please check back later."
                  error-message="There was a problem loading the Notification Types. Please try again later."
                />
              </template>

              <el-table-column label="Notification">
                <template v-slot="{ row }">
                  {{ row.notificationType.title }}
                </template>
              </el-table-column>

              <el-table-column label="Description">
                <template v-slot="{ row }">
                  {{ row.notificationType.description }}
                </template>
              </el-table-column>

              <el-table-column label="Email" width="120" align="center">
                <template v-slot="{ row }">
                  <el-checkbox
                    :value="row.email"
                    :disabled="!emailNotification.value"
                    @input="updateUserNotificationType(row, { email: $event })"
                  />
                </template>
              </el-table-column>

              <el-table-column label="In Platform" width="120" align="center">
                <template v-slot="{ row }">
                  <el-checkbox
                    :value="row.in_platform"
                    :disabled="!inPlatformNotification.value"
                    @input="
                      updateUserNotificationType(row, {
                        in_platform: $event
                      })
                    "
                  />
                </template>
              </el-table-column>
            </el-table>
          </template>
        </user-mutation>
      </div>
    </div>
  </div>
</template>

<script>
import { infoCircled as infoIcon } from '@/vendor/icons';
import UserNotificationSettingsQuery from '@/components/Queries/User/UserNotificationSettingsQuery';
import UserMutation from '@/components/Mutations/Core/UserMutation';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';

const NOTIFICATION_SETTINGS = {
  EMAIL: 'notifications-email',
  TEXT: 'notifications-text',
  IN_PLATFORM: 'notifications-in-platform',
  DESKTOP: 'notifications-desktop'
};

export default {
  components: {
    TableEmptySlot,
    UserMutation,
    UserNotificationSettingsQuery
  },
  data() {
    return {
      // Consts
      NOTIFICATION_SETTINGS,

      isLoading: false,
      notificationSettings: null,

      // Icons
      infoIcon
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    isPrimaryContact() {
      return (
        this.user.email ===
        this.$store.getters['auth/supplier'].primaryContact.email
      );
    },
    notificationTypes() {
      return (
        this.notificationSettings &&
        this.notificationSettings.userNotificationTypes
      );
    },
    emailNotification() {
      return (
        this.notificationSettings.settings.find(
          s => s.slug === NOTIFICATION_SETTINGS.EMAIL
        ) || {}
      );
    },
    inPlatformNotification() {
      return (
        this.notificationSettings.settings.find(
          s => s.slug === NOTIFICATION_SETTINGS.IN_PLATFORM
        ) || {}
      );
    }
  },
  methods: {}
};
</script>
