<template>
  <section>
    <div class="font-bold text-md mb-2">Pending</div>
    <el-table
      v-loading="isLoading"
      border
      class="el-table-slim"
      :data="team"
      :row-class-name="rowClass"
    >
      <table-empty-slot
        slot="empty"
        :is-loading="isLoading"
        :has-data="!!team"
        class="p-4"
      >
        There are no pending team members.
        <a @click="$emit('invite')">Invite a new member</a>
      </table-empty-slot>

      <el-table-column label="Name">
        <template v-slot="{ row }">
          {{ row.name }}
          <div class="text-dark-silver">{{ row.email }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template v-slot="{ row }">
          <div :class="statusClass(row)">
            {{ InviteLabels[row.status] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Action" align="center" width="100">
        <template v-slot="{ row }">
          <el-popover
            placement="bottom-end"
            trigger="click"
            width="180"
            :disabled="!canEdit"
          >
            <tooltip-icon
              slot="reference"
              :is-disabled="!canEdit"
              :icon="ellipsisIcon"
              reference-class="cursor-pointer hover:text-dark-blue px-4"
              icon-class="icon-lg text-blue"
            />
            <el-button
              v-if="row.status === InviteStatus.REQUESTED"
              class="button-plain hover:text-blue p-0 pb-4"
              @click="$emit('add', row)"
            >
              Add To Team
            </el-button>
            <el-button
              class="button-plain hover:text-blue p-0"
              @click="$emit('reject', row)"
            >
              {{
                row.status === InviteStatus.INVITE_SENT
                  ? 'Revoke Invitation'
                  : 'Reject'
              }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import { chat as chatIcon, ellipsisV as ellipsisIcon } from '@/vendor/icons';
import { InviteLabels, InviteStatus } from '@/constants';
import TooltipIcon from '@/components/Core/TooltipIcon';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';

export default {
  components: {
    TableEmptySlot,
    TooltipIcon
  },
  props: {
    team: {
      type: Array,
      default: () => []
    },
    isLoading: Boolean
  },
  data() {
    return {
      chatIcon,
      ellipsisIcon,
      InviteStatus,
      InviteLabels
    };
  },
  computed: {
    canEdit() {
      const isSupplier = this.$store.getters['auth/isSupplier'];
      if (isSupplier) {
        return this.$can('pub_manage_team_members');
      } else {
        return this.$can('buyer_manage_team_members');
      }
    }
  },
  methods: {
    rowClass({ row: user }) {
      if (user.status && user.status === InviteStatus.REQUESTED) {
        return 'row-error';
      }
      return null;
    },
    statusClass(user) {
      if (user.status && user.status === InviteStatus.REQUESTED) {
        return 'text-red';
      }
      return null;
    }
  }
};
</script>
