<template>
  <el-dialog
    append-to-body
    modal-append-to-body
    width="50%"
    title="Are you sure?"
    :visible="visible"
    :close-on-click-modal="false"
    @update:visible="$event || $emit('close')"
    @close="$emit('close')"
  >
    <div class="text-center">
      Are you sure you want to {{ title.toLowerCase() }} this user?
      <div class="mt-4">
        If you want to add them later, they will need to send another request,
        or you can send an invite using the “invite user” button above.
      </div>
    </div>
    <template slot="footer">
      <el-button v-if="!isLoading" class="button-white" @click="$emit('close')">
        Never mind
      </el-button>
      <el-button v-if="!isLoading" class="button-red" @click="onSubmit">
        {{ title }}
      </el-button>
      <template v-else>
        <loading-button />
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { LoadingButton } from '@/components/Core/Loading';

export default {
  components: {
    LoadingButton
  },
  props: {
    title: {
      type: String,
      default: 'Remove'
    },
    isLoading: Boolean,
    visible: Boolean
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$emit('submit');
    }
  }
};
</script>
