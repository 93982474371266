<template>
  <el-dialog
    append-to-body
    modal-append-to-body
    width="50%"
    :title="config.title"
    :visible="visible"
    :close-on-click-modal="false"
    @update:visible="$event || $emit('close')"
    @close="$emit('close')"
    @closed="resetDialog"
  >
    <el-form
      ref="editForm"
      label-position="top"
      label-width="100px"
      :disabled="isLoading"
      :rules="rules"
      :model="editForm"
    >
      <custom-el-form-item
        v-if="action === 'INVITE'"
        class="mb-4"
        label-class="font-semibold"
        label="Email"
        prop="email"
      >
        <el-input v-model="editForm.email" placeholder="Enter Email" />
      </custom-el-form-item>
      <custom-el-form-item
        :disabled="!canEditRole"
        class="mb-4"
        label="User Role"
        label-class="font-semibold"
        prop="role"
      >
        <template slot="content">
          <div class="w-48">
            This determines permissions to perform actions throughout the
            flytedesk platform.
            <a
              href="https://intercom.help/flytedesk/en/articles/3102562-assigning-team-roles"
              target="_blank"
            >
              Learn more about user roles.
            </a>
          </div>
        </template>
        <div v-if="isMyAccount" slot="disabled">
          You cannot edit your own user role.
        </div>
        <el-select
          v-model="editForm.role"
          :disabled="!canEditRole"
          class="w-full"
          placeholder="Select user role"
          filterable
          value-key="id"
          :loading="isLoading"
        >
          <template v-if="canEditRole">
            <el-option
              v-for="role in roleOptions"
              :key="`role-${role.value}`"
              :label="role.name"
              :value="role.value"
            >
            </el-option>
          </template>
        </el-select>
      </custom-el-form-item>

      <custom-el-form-item label="Additional Info" label-class="font-semibold">
        <el-checkbox-group v-model="contacts">
          <el-checkbox :label="optionalContactType">
            {{ ContactNames[optionalContactType] }}
          </el-checkbox>
          <template>
            <el-tooltip
              position="bottom"
              effect="light"
              :disabled="!isPrimaryContact"
            >
              <div slot="content" class="text-left max-w-sm">
                A primary contact is required. To remove this selection from
                this user, designate a different user as primary contact.
              </div>
              <el-checkbox
                :disabled="isPrimaryContact"
                :label="ContactType.PRIMARY"
                @change="primaryWarningVisible = !primaryWarningVisible"
              >
                {{ ContactNames.PRIMARY }}
              </el-checkbox>
            </el-tooltip>
            <el-checkbox
              :label="ContactType.BILLING"
              @change="toggleBillingWarning"
            >
              {{ ContactNames.BILLING }}
            </el-checkbox>
          </template>
        </el-checkbox-group>
        <div
          v-if="
            action === 'EDIT' &&
              (primaryWarningVisible ||
                (billingWarningVisible && hasBillingContact))
          "
          class="mt-4 flex items-center"
        >
          <icon :icon="attention" class="text-yellow icon-xl mr-3" />
          <div>
            Your organization already has a {{ warningType }}. Selecting
            {{ warningType }} for this user will remove the selection from your
            current {{ warningType }}.
          </div>
        </div>
      </custom-el-form-item>

      <custom-el-form-item
        label="User Type"
        label-class="font-semibold"
        prop="type"
      >
        <el-checkbox-group v-model="editForm.tags">
          <el-checkbox
            v-for="teamMemberType in teamMemberTypes"
            :key="'member-type-' + teamMemberType"
            :label="teamMemberType"
          >
            {{ teamMemberType }}
          </el-checkbox>
        </el-checkbox-group>
      </custom-el-form-item>
    </el-form>
    <template slot="footer">
      <el-button v-if="!isLoading" class="button-white" @click="$emit('close')">
        Cancel
      </el-button>
      <el-button v-if="!isLoading" class="button-blue" @click="onSubmit">
        {{ config.button }}
      </el-button>
      <template v-else>
        <loading-button />
      </template>
    </template>
  </el-dialog>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { LoadingButton } from '@/components/Core/Loading';
import {
  ContactNames,
  ContactType,
  RoleEnums,
  RoleSlugs,
  SupplierTeamMemberType
} from '@/constants';
import { attention } from '@/vendor/icons';

export default {
  components: {
    CustomElFormItem,
    LoadingButton
  },
  props: {
    action: {
      type: String,
      default: 'INVITE'
    },
    user: {
      type: Object,
      default: null
    },
    userContactTypes: {
      type: Array,
      default: () => []
    },
    isLoading: Boolean,
    optionalContactType: {
      type: String,
      default: ContactType.ADVISOR
    },
    roleOptions: {
      type: Array,
      default: () => []
    },
    visible: Boolean,
    billingContact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      attention,
      contacts: this.userContactTypes,
      ContactNames,
      ContactType,
      editForm: {
        role: this.defaultRole(this.user),
        tags: this.user?.supplierUser?.tags || [],
        email: this.user?.email || ''
      },
      rules: {
        role: [{ required: true, message: 'message', trigger: 'blur' }]
      },
      selectedRole: null,
      billingWarningVisible: false,
      primaryWarningVisible: false
    };
  },
  computed: {
    teamMemberTypes() {
      return Object.values(SupplierTeamMemberType);
    },
    canEditRole() {
      return !this.isSuperAdmin && !this.isMyAccount;
    },
    config() {
      switch (this.action) {
        case 'ADD':
          return { title: 'Add to Team', button: 'Add' };
        case 'EDIT':
          return {
            title: this.user ? this.user.name : 'Edit User',
            button: 'Save'
          };
        default:
          return { title: 'Invite User', button: 'Send Invite' };
      }
    },
    hasBillingContact() {
      return this.billingContact !== null;
    },
    isBillingContact() {
      return this.userContactTypes.includes(ContactType.BILLING);
    },
    isPrimaryContact() {
      return this.userContactTypes.includes(ContactType.PRIMARY);
    },
    isSuperAdmin() {
      return (
        this.user &&
        this.user.roles &&
        !!this.user.roles.find(role => role.slug === RoleSlugs.SUPER_ADMIN)
      );
    },
    isMyAccount() {
      return (
        this.user &&
        this.user.__typename === 'User' &&
        this.user.id === this.$store.getters['auth/user'].id
      );
    },
    warningType() {
      if (this.primaryWarningVisible && this.billingWarningVisible) {
        return 'primary and billing contact';
      } else if (this.primaryWarningVisible) {
        return 'primary contact';
      } else if (this.billingWarningVisible) {
        return 'billing contact';
      } else {
        return '';
      }
    }
  },
  watch: {
    userContactTypes(newTypes) {
      this.contacts = newTypes;
    },
    user(newUser) {
      this.editForm = {
        role: this.defaultRole(newUser),
        tags: newUser?.supplierUser?.tags || [],
        email: newUser?.email || ''
      };
    }
  },
  methods: {
    resetDialog() {
      this.contacts = this.userContactTypes;
      this.editForm.role = this.defaultRole(this.user);
      this.$refs.editForm.resetFields();
      this.billingWarningVisible = false;
      this.primaryWarningVisible = false;
    },
    defaultRole(user) {
      if (user && user.roles) {
        return RoleEnums[user.roles[0].slug];
      }
      return null;
    },
    onSubmit() {
      const form = {
        contacts: this.contacts,
        email: this.editForm.email,
        tags: this.editForm.tags,
        role: this.isSuperAdmin || this.isMyAccount ? null : this.editForm.role
      };
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$emit('submit', form);
        }
      });
    },
    toggleBillingWarning() {
      if (!this.isBillingContact && this.hasBillingContact) {
        this.billingWarningVisible = !this.billingWarningVisible;
      } else if (!this.hasBillingContact) {
        this.billingWarningVisible = false;
      }
    }
  }
};
</script>
