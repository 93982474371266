var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-notifications-tab"},[_c('user-notification-settings-query',{attrs:{"loading":_vm.isLoading},on:{"update:loading":function($event){_vm.isLoading=$event},"data":function($event){_vm.notificationSettings = $event.me}}}),_c('div',{staticClass:"global-settings border-bottom pb-10 mb-10"},[_c('h2',[_vm._v("Global Notification Settings")]),_c('div',{staticClass:"mt-2"},[_vm._v(" These settings affect all other notifications and will enable or disable notifications of that type across the platform ")]),(_vm.isPrimaryContact)?_c('div',{staticClass:"mt-3 bold italic text-dark-silver"},[_vm._v(" You are the primary contact for your organization, so you will always be notified about actions relating to flytedesk national campaigns. "),_c('br'),_vm._v(" You can still use the settings below to customize notifications related to your ad shop campaigns. ")]):_vm._e(),_c('user-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var updateUserSetting = ref.updateUserSetting;
return [(_vm.notificationSettings)?_c('div',{staticClass:"global-notification-settings mt-5"},[_c('el-checkbox',{attrs:{"value":_vm.emailNotification.value},on:{"input":function($event){return updateUserSetting(_vm.NOTIFICATION_SETTINGS.EMAIL, $event)}}},[_vm._v(" Email Notifications "),_c('el-tooltip',{staticClass:"ml-2",attrs:{"effect":"light"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"text-md p-2"},[_c('div',[_vm._v("Enable or Disable Email notifications globally.")]),_c('div',{staticClass:"text-dark-red mt-5"},[_vm._v(" NOTE: You will still receive transactional email communication from us if this is disabled! ")])])]},proxy:true}],null,true)},[_c('icon',{attrs:{"icon":_vm.infoIcon}})],1)],1),_c('el-checkbox',{attrs:{"value":_vm.inPlatformNotification.value},on:{"input":function($event){return updateUserSetting(_vm.NOTIFICATION_SETTINGS.IN_PLATFORM, $event)}}},[_vm._v(" In Platform Notifications "),_c('el-tooltip',{staticClass:"ml-2",attrs:{"effect":"light"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"text-md p-2"},[_c('div',[_c('div',[_vm._v(" Enable or Disable In Platform notifications globally. ")]),_c('div',{staticClass:"mt-3"},[_vm._v(" These are the notifications that appear in the Notifications Panel while you are using the Flytedesk platform. ")])])])]},proxy:true}],null,true)},[_c('icon',{attrs:{"icon":_vm.infoIcon}})],1)],1)],1):_vm._e()]}}])})],1),_c('div',{staticClass:"user-type-notifications"},[_c('h2',[_vm._v("Notification Types")]),_c('div',{staticClass:"mt-5"},[_c('user-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updateUserNotificationType = ref.updateUserNotificationType;
var isSaving = ref.isSaving;
return [_c('el-table',{staticClass:"el-table-slim",attrs:{"border":"","data":_vm.notificationTypes},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"is-loading":_vm.isLoading,"has-data":!!_vm.notificationTypes,"empty-message":"There are no notifications that can be edited at this time. Please check back later.","error-message":"There was a problem loading the Notification Types. Please try again later."}})]},proxy:true}],null,true)},[_c('el-table-column',{attrs:{"label":"Notification"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.notificationType.title)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.notificationType.description)+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Email","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":row.email,"disabled":!_vm.emailNotification.value},on:{"input":function($event){return updateUserNotificationType(row, { email: $event })}}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"In Platform","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":row.in_platform,"disabled":!_vm.inPlatformNotification.value},on:{"input":function($event){return updateUserNotificationType(row, {
                      in_platform: $event
                    })}}})]}}],null,true)})],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }