<template>
  <el-dialog
    append-to-body
    modal-append-to-body
    width="50%"
    title="Reset Password"
    :visible="visible"
    :close-on-click-modal="false"
    @update:visible="$event || $emit('close')"
    @close="$emit('close')"
  >
    <div class="text-center">
      Are you sure you want to Reset password for {{ user && user.name }}?
      <div class="mt-4">
        A password reset email will be sent to
        <strong>{{ user && user.email }}</strong
        >.
      </div>
    </div>
    <template slot="footer">
      <el-button v-if="!isLoading" class="button-white" @click="$emit('close')">
        Cancel
      </el-button>
      <el-button v-if="!isLoading" class="button-blue" @click="onSubmit">
        Send Password Reset Email
      </el-button>
      <template v-else>
        <loading-button />
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { LoadingButton } from '@/components/Core/Loading';

export default {
  components: {
    LoadingButton
  },
  props: {
    title: {
      type: String,
      default: 'Send'
    },
    isLoading: Boolean,
    visible: Boolean,
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$emit('submit');
    }
  }
};
</script>
