<template>
  <section>
    <div class="flex justify-between">
      <div>
        <h2>Team Members</h2>
        <div class="text-md mt-2">
          Add and manage members of your team.
        </div>
      </div>
      <el-button
        class="button-blue h-11"
        :disabled="!$can('pub_manage_team_members')"
        @click="onOpenEditDialog(null, 'INVITE')"
      >
        Invite User
      </el-button>
    </div>
    <supplier-team-query
      :invites-filter="invitesFilter"
      @data="supplier = $event"
    >
      <template v-slot="{ isLoading }">
        <supplier-team-mutation @data="onData">
          <template
            v-slot="{
              loading,
              sendSupplierMemberInvite,
              rejectSupplierMemeberRequest,
              setSupplierMemberRole,
              setSupplierMemberTags,
              setSupplierContacts,
              removeSupplierMember
            }"
          >
            <!-- Invites Table -->
            <pending-team-table
              class="mt-8"
              :is-loading="isLoading"
              :team="supplier && supplier.invites"
              @invite="onOpenEditDialog(null, 'INVITE')"
              @add="onOpenEditDialog($event, 'ADD')"
              @reject="onOpenRemoveDialog($event, 'Reject')"
            />

            <!-- Team Members Table -->
            <current-team-table
              class="mt-8"
              :team="supplier && supplier.teamMembers"
              :primary-contact="supplier && supplier.primaryContact"
              :billing-contact="supplier && supplier.billingContact"
              :optional-contacts="supplier && supplier.advisorContacts"
              :is-loading="isLoading"
              @invite="onOpenEditDialog(null, 'INVITE')"
              @edit="onOpenEditDialog($event, 'EDIT')"
              @remove="onOpenRemoveDialog($event, 'Remove')"
              @resetPassword="
                onOpenResetPasswordDialog($event, 'Reset Password')
              "
            />

            <edit-team-member-dialog
              :user="selectedUser"
              :user-contact-types="userContactTypes"
              :action="dialogType"
              :visible="editMemberDialogVisible"
              :is-loading="loading"
              :role-options="roleOptions"
              :billing-contact="supplier && supplier.billingContact"
              @submit="
                onSubmitEdit(
                  $event,
                  sendSupplierMemberInvite,
                  setSupplierMemberRole,
                  setSupplierMemberTags,
                  setSupplierContacts
                )
              "
              @close="editMemberDialogVisible = false"
            />
            <remove-team-member-dialog
              :is-loading="loading"
              :title="removeDialogTitle"
              :visible="removeMemberDialogVisible"
              @submit="
                onSubmitRemove(
                  rejectSupplierMemeberRequest,
                  removeSupplierMember
                )
              "
              @close="removeMemberDialogVisible = false"
            />
          </template>
        </supplier-team-mutation>

        <password-mutation
          @data="onData"
          @error="onSendResetPasswordEmailError"
        >
          <template v-slot="{ loading, sendResetPasswordEmail }">
            <reset-password-for-team-member-dialog
              :is-loading="loading"
              :title="resetPasswordDialogTitle"
              :visible="resetPasswordDialogVisible"
              :user="selectedUser"
              @submit="onSubmitResetPassword(sendResetPasswordEmail)"
              @close="resetPasswordDialogVisible = false"
            />
          </template>
        </password-mutation>
      </template>
    </supplier-team-query>

    <div v-if="supplier" class="mt-8">
      <div class="font-bold text-md mb-2">Contacts</div>

      <div class="contact-list">
        <transition-group class="animate-position flex" name="fade">
          <div
            v-for="contact in contacts"
            :key="'contact-' + contact.id + '-title-' + contact.title"
            class="shadow p-5 mr-5"
          >
            <contact-info :contact="contact">
              <template #title>
                <h4 class="mb-3">{{ contact.title }}</h4>
              </template>
            </contact-info>
          </div>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script>
import CurrentTeamTable from '@/components/Core/Settings/CurrentTeamTable';
import EditTeamMemberDialog from '@/components/Core/Settings/EditTeamMemberDialog';
import RemoveTeamMemberDialog from '@/components/Core/Settings/RemoveTeamMemberDialog';
import ResetPasswordForTeamMemberDialog from '@/components/Core/Settings/ResetPasswordForTeamMemberDialog';
import PendingTeamTable from '@/components/Core/Settings/PendingTeamTable';
import { PasswordMutation, SupplierTeamMutation } from '@/components/Mutations';
import { SupplierTeamQuery } from '@/components/Queries';
import { ContactType, InviteStatus, SupplierRoleSelections } from '@/constants';
import { xor } from 'lodash';
import ContactInfo from '../Core/ContactInfo';

export default {
  components: {
    ContactInfo,
    CurrentTeamTable,
    EditTeamMemberDialog,
    RemoveTeamMemberDialog,
    ResetPasswordForTeamMemberDialog,
    PendingTeamTable,
    SupplierTeamMutation,
    PasswordMutation,
    SupplierTeamQuery
  },
  data() {
    return {
      dialogType: 'INVITE',
      removeDialogTitle: undefined,
      resetPasswordDialogTitle: undefined,
      editMemberDialogVisible: false,
      removeMemberDialogVisible: false,
      resetPasswordDialogVisible: false,
      isMutatingMember: false,
      invitesFilter: {
        status: {
          '!=': [InviteStatus.REJECTED, InviteStatus.ACCEPTED]
        }
      },
      supplier: null,
      selectedUser: null,
      isSendResetPasswordEmailSuccess: false
    };
  },
  computed: {
    contacts() {
      let contacts = [{ ...this.supplier.primaryContact, title: 'Primary' }];

      if (this.supplier.billingContact) {
        contacts.push({ ...this.supplier.billingContact, title: 'Billing' });
      }

      if (this.supplier.advisorContacts) {
        this.supplier.advisorContacts.map(c =>
          contacts.push({ ...c, title: 'Advisor' })
        );
      }

      return contacts;
    },
    roleOptions() {
      return SupplierRoleSelections;
    },
    userContactTypes() {
      let types = [];
      if (!this.supplier || !this.selectedUser) return [];
      if (this.supplier.primaryContact.user_id === this.selectedUser.id) {
        types.push(ContactType.PRIMARY);
      }
      if (
        this.supplier.billingContact &&
        this.supplier.billingContact.user_id === this.selectedUser.id
      ) {
        types.push(ContactType.BILLING);
      }
      if (
        this.supplier.advisorContacts &&
        this.supplier.advisorContacts.find(
          contact => contact.user_id === this.selectedUser.id
        )
      ) {
        types.push(ContactType.ADVISOR);
      }
      return types;
    }
  },
  methods: {
    onOpenEditDialog(user, dialogType) {
      this.dialogType = dialogType;
      this.selectedUser = user;
      this.editMemberDialogVisible = true;
    },
    onOpenRemoveDialog(user, dialogTitle) {
      this.removeDialogTitle = dialogTitle;
      this.selectedUser = user;
      this.removeMemberDialogVisible = true;
    },
    onOpenResetPasswordDialog(user, dialogTitle) {
      this.resetPasswordDialogTitle = dialogTitle;
      this.selectedUser = user;
      this.resetPasswordDialogVisible = true;
    },
    onData(data) {
      this.isSendResetPasswordEmailSuccess = true;
      this.selectedUser = null;
      this.editMemberDialogVisible = false;
      this.removeMemberDialogVisible = false;
      this.resetPasswordDialogVisible = false;
      if (
        data.mutateUser &&
        (data.mutateUser.setUserRole || data.mutateUser.setUserContact)
      ) {
        this.$message.success(
          "Your team member's information has been updated"
        );
      }

      if (data.sendResetPasswordEmailMutation) {
        this.$message.success('Password reset email sent!');
      }
    },
    onSendResetPasswordEmailError() {
      this.isSendResetPasswordEmailSuccess = false;
    },
    onSubmitEdit(
      form,
      sendSupplierMemberInvite,
      setSupplierMemberRole,
      setSupplierMemberTags,
      setSupplierContacts
    ) {
      if (this.dialogType === 'EDIT') {
        const contactsInput = xor(form.contacts, this.userContactTypes).map(
          contactType => ({
            contactType,
            addContact: form.contacts.includes(contactType)
          })
        );
        setSupplierContacts(this.selectedUser.id, contactsInput);
        if (form.role) {
          setSupplierMemberRole(this.selectedUser.id, form.role);
        }

        setSupplierMemberTags(this.selectedUser.id, form.tags);
      } else {
        sendSupplierMemberInvite({
          email: form.email,
          role: form.role,
          contactType: form.contacts.join(',')
        });
      }
    },
    onSubmitRemove(rejectSupplierMemeberRequest, removeSupplierMember) {
      if (this.selectedUser.__typename === 'Invite') {
        rejectSupplierMemeberRequest(this.selectedUser.id);
      } else {
        removeSupplierMember(this.selectedUser.id);
      }
    },
    onSubmitResetPassword(sendResetPasswordEmail) {
      sendResetPasswordEmail(this.selectedUser.email);
    }
  }
};
</script>
