<template>
  <div>
    <h2>Password</h2>
    <el-form
      ref="passwordForm"
      class="mt-6"
      :disabled="isLoading"
      :rules="rules"
      label-position="top"
      :model="passwordForm"
    >
      <custom-el-form-item
        :is-loading="isLoading"
        label-class="font-semibold"
        label="Current Password"
        prop="current_password"
      >
        <el-input
          v-model="passwordForm.current_password"
          type="password"
          placeholder="Current Password"
          @focus="touched = true"
        />
      </custom-el-form-item>

      <div class="flex flex-row mt-4">
        <custom-el-form-item
          :is-loading="isLoading"
          label-class="font-semibold"
          class="w-1/2 mr-3"
          label="New Password"
          prop="new_password"
        >
          <el-input
            v-model="passwordForm.new_password"
            type="password"
            placeholder="New Password"
            @focus="touched = true"
          />
        </custom-el-form-item>
        <custom-el-form-item
          :is-loading="isLoading"
          label-class="font-semibold"
          class="w-1/2"
          label="Confirm Password"
          prop="confirm_password"
        >
          <el-input
            v-model="passwordForm.confirm_password"
            type="password"
            placeholder="Confirm Password"
            @focus="touched = true"
          />
        </custom-el-form-item>
      </div>
    </el-form>
    <el-button
      v-if="!isLoading"
      :loading="!touched"
      class="button-blue w-full mt-8"
      @click="onSubmit"
    >
      Save Changes
    </el-button>
    <loading-button v-else class="w-full mt-8" />
  </div>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { LoadingButton } from '@/components/Core/Loading';

export default {
  components: {
    CustomElFormItem,
    LoadingButton
  },
  props: {
    isLoading: Boolean
  },
  data() {
    return {
      passwordForm: {},
      rules: {
        current_password: [
          {
            required: true,
            message: 'Please enter your current password',
            trigger: 'blur'
          }
        ],
        new_password: [
          { required: true, validator: this.validatePassword, trigger: 'blur' }
        ],
        confirm_password: [
          {
            required: true,
            validator: this.validatePasswordConfirmation,
            trigger: 'blur'
          }
        ]
      },
      touched: false
    };
  },
  methods: {
    onSubmit() {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.passwordForm);
          this.$refs.passwordForm.clearValidate();
          this.touched = false;
        }
      });
    },
    enableButton() {
      this.touched = true;
    },
    resetFields() {
      this.$refs.passwordForm.resetFields();
    },
    validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error('Please input a password'));
      }
      if (value.length < 8) {
        callback(
          new Error('Passwords must be at least 8 charecters in length')
        );
      }
      callback();
    },
    validatePasswordConfirmation(rule, value, callback) {
      if (!value) {
        callback(new Error('Please input a password'));
      }
      if (value !== this.passwordForm.new_password) {
        callback(new Error('Passwords do not match'));
      }
      callback();
    }
  }
};
</script>
